'use strict';

angular.module('webPartnerPortalApp')
  .factory('EventDispatchingSvc', ['$rootScope',function($rootScope) {
    function EventDispatchingSvc($scope) {
        var delisteners = [];

        if (!$scope) {
            throw new Error('A EventDispatchingSvc must have $scope to function. It wasn\'t found.');
        }

        this.dispatch = angular.bind($rootScope, $rootScope.$emit);

        this.listen = function() {
            var args = Array.prototype.slice.call(arguments),
                deListenFunc = $rootScope.$on.apply($rootScope, args);
            delisteners.push(deListenFunc);
            return deListenFunc;
        };

        $scope.$on('$destroy', function() {
            delisteners.forEach(function(deListenFunc) {
                if (angular.isFunction(deListenFunc)) {
                    deListenFunc.call();
                }
            });
        });
    }

    return EventDispatchingSvc;
}]);
